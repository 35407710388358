<template>
  <div class="wrapper">
    <!-- Navbar -->
    <MerchantNavbar />

    <!-- Content -->
    <b-container fluid class="mt-3">
      <!-- AUTO LOGOUT -->
      <!-- <AutoLogout /> -->

      <div v-if="loading" class="text-center">
        <b-spinner class="m-5" label="Loading..."></b-spinner>
      </div>

      <Content v-else></Content>
    </b-container>

    <!-- BOTTOM NAV -->
    <MerchantBottomNavbar v-if="displayNavItems" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { userStoreExists } from "../../apis/user";
import { getStore } from "../../apis/merchant";

import MerchantNavbar from "../../components/navbars/merchant/MerchantNavbar";
import MerchantBottomNavbar from "../../components/navbars/merchant/MerchantBottomNavbar";
// import PostLoginBottomNavbar from "../../components/navbars/PostLoginBottomNavbar";

export default {
  components: {
    MerchantNavbar,
    MerchantBottomNavbar,
  },
  created() {
    // load masters
    this.setUnits();
    this.setOrderStatuses();

    this.loading = true;
    // Checking if user is a merchant or not, if not, show the register form page

    userStoreExists(this.token)
      .then((data) => {
        const exists = data.exists;
        if (exists) {
          // get store data & redirect to dashboard
          getStore(this.token)
            .then((data) => {
              this.setStore(data);
              this.loading = false;
              // if path is only /postLogin, redirect to Dashboard
              // console.log(this.$route);
              // if (this.$route.path === "/postLogin") {
              //   this.$router.push({ name: "StoreDashboard" });
              // }
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          // send to register store page
          this.loading = false;
          this.$router.replace({ name: "RegisterStoreForm" });
        }
      })
      .catch(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      token: "login/getToken",
      store: "merchant/store/getStore",
    }),
    displayNavItems() {
      const storeExists = this.store ? true : false;
      return storeExists;
    },
  },
  methods: {
    ...mapActions({
      setStore: "merchant/store/setStore",
      setUnits: "masters/setUnits",
      setOrderStatuses: "masters/setOrderStatuses",
    }),
  },
};
</script>

<style scoped>
.container,
.container-fluid {
  margin-bottom: 75px;
}
</style>
