<template>
	<CommonNavbar>
		<!-- Right aligned nav items -->
		<b-navbar-nav class="ml-auto">
			<b-nav-item href="#" @click.prevent="logout">
				<b-icon-box-arrow-right />
				Logout
			</b-nav-item>
		</b-navbar-nav>
	</CommonNavbar>
</template>

<script>
import { mapActions } from "vuex";
import CommonNavbar from "../CommonNavbar";
export default {
	components: {
		CommonNavbar,
	},
	methods: {
		...mapActions({
			storeLogout: "login/logout",
		}),
		logout() {
			this.storeLogout().then(() => {
				this.$router.replace("/");
			});
		},
	},
};
</script>
